import React, { useEffect, useState } from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    theme,
    Flex,
    Avatar,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack,
    useColorMode,
    Center,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton
  } from '@chakra-ui/react';
  import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import { FaGithub, FaTwitter, FaBars } from 'react-icons/fa';
import {
  Link as RouteLink
} from "react-router-dom";
  function Header() {
    const { colorMode } = useColorMode()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <>
        <Box px={8} py="30px">
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <RouteLink to={`/`} className="menu-item">Hydrogen Labs</RouteLink>

            <Flex alignItems={'center'}>
              <Stack direction={'row'} spacing={7}>
              {(width > 800) ? 
                (<>
                  <RouteLink to={`/team`} className="menu-item">Team</RouteLink>
                  <RouteLink to={`/portfolio`} className="menu-item">Portfolio</RouteLink>
                </>
                ) : (
                <IconButton ref={btnRef} onClick={onOpen} aria-label='Open Nav Menu' icon={<FaBars />} />
                )
              }
              </Stack>
            </Flex>
          </Flex>
        </Box>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader><Stack direction={'row'} spacing={7}><Logo w="30px" pointerEvents="none" style={{marginRight:20}}/><>Menu</></Stack></DrawerHeader>
  
            <DrawerBody>
              <RouteLink to={`/`} className="menu-item">Home</RouteLink>
              <br/><br/>
              <RouteLink to={`/team`} className="menu-item">Team</RouteLink>
              <br/><br/>
              <RouteLink to={`/portfolio`} className="menu-item">Portfolio</RouteLink>
            </DrawerBody>
  
            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        </>
);
}

export default Header;