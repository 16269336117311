import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Header from "./Header";
import Footer from "./Footer";

function Team() {
  const { colorMode } = useColorMode()

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Box textAlign="center" bg={useColorModeValue('gray.100', 'gray.900')} fontSize="xl">

      <Header />

      <Grid className="team-section" minH={"calc(100vh - 248px)"} p={0} alignItems="center" justifyContent={"center"}>
        <Box>
          <Box>
            <span style={{ fontSize: "0.9rem", color: useColorModeValue('gray.300', 'gray.400') }}>Co-Founder</span> <br />
            Meir Bank <br />
            <Box style={{ display: "inline-flex" }}>
              <a style={{ color: "#4d4dff", margin: "5px", marginRight: "12px" }} href='https://www.linkedin.com/in/meir-hydrogen/'><FaLinkedin /></a><a style={{ marginLeft: "12px", color: "#4d4dff", margin: "5px" }} href='https://twitter.com/0xmeir'><FaTwitter /></a> <br />
            </Box>
          </Box>
          <br /><br />
          <Box>
            <span style={{ fontSize: "0.9rem", color: useColorModeValue('gray.300', 'gray.400') }}>Co-Founder</span> <br />
            David Mass <br />
            <Box style={{ display: "inline-flex" }}>
              <a style={{ color: "#4d4dff", margin: "5px", marginRight: "12px" }} href='https://www.linkedin.com/in/david-m-56a2131b/'><FaLinkedin /></a> <a style={{ marginLeft: "12px", color: "#4d4dff", margin: "5px" }} href='https://twitter.com/David_DeFi_'><FaTwitter /></a>
            </Box>
          </Box>
        </Box>
      </Grid>



      <Footer />

    </Box>
  );
}

export default Team;