import React, { useEffect, useState, useRef } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Image,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { FaGithub, FaTwitter } from 'react-icons/fa';
import Header from "./Header";
import Footer from "./Footer";
import logo from './logo.png';

function Landing() {
  const { colorMode } = useColorMode()

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [rotationSpeed, setRotationSpeed] = useState(50.00);
  const [rotationAngle, setRotationAngle] = useState(0.000);

  useEffect(() => {
    window.addEventListener('mousemove', setNewSpeed);
    const interval = setInterval(setNextAngle, 50);
    return () => {
      window.removeEventListener(
        'mousemove',
        setNewSpeed
      );
      clearInterval(interval);
    }
  }, [rotationAngle]);

  function setNextAngle() {
    var newAmount = rotationAngle + ((1 / rotationSpeed) * 15)
    setRotationAngle(newAmount);
    console.log("rotating", newAmount, rotationAngle, rotationSpeed)
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const setNewSpeed = (event) => {
    const middleOfPageX = width / 2;
    const middleOfPageY = (height) / 2;
    const distanceFromMiddleX = event.clientX / middleOfPageX; // 1= middle 0=far 2=far
    const absDistanceFromMiddleX = Math.abs(1 - distanceFromMiddleX); // should return a range from 0 to 1, 0 being in the middle 1 being at the edge
    const distanceFromMiddleY = event.clientY / middleOfPageY; // 1= middle 0=far 2=far
    const absDistanceFromMiddleY = Math.abs(1 - distanceFromMiddleY); // should return a range from 0 to 1, 0 being in the middle 1 being at the edge
    const absDistanceFromMiddle = ((absDistanceFromMiddleX + absDistanceFromMiddleY) / 2) - 0.000000001
    var speed = 1 + (absDistanceFromMiddle * 5)
    console.log(absDistanceFromMiddle, (100 - (absDistanceFromMiddle * 100)), speed)
    if (speed == NaN || speed == undefined || !speed) {
      speed = 50;
    }
    setRotationSpeed(speed);
    console.log("set speed", speed, rotationSpeed)
  }


  return (
    <Box textAlign="center" bg={useColorModeValue('gray.100', 'gray.900')} fontSize="xl">

      <Header />

      <Grid minH={height > 800 ? "calc(100vh - 248px)" : "calc(100vh - 260px)"} p={0}>

        <VStack spacing={8} maxW="100vw" justifyContent={height > 800 ? "center" : "auto"} style={{ marginTop: (height > 800 || height < 645) ? "0" : "20px", position: "relative" }}>
          <Image w={height > 800 && width > 800 ? "300px" : "200px"} maxW="80vw" pointerEvents="none" className="noselect logo" id="rotatooor" style={{ transform: `rotate(${rotationAngle}deg)` }} src={logo} />;
          {/*<Text w="450px" maxW="80%" style={{ fontSize: (width > 800 && height) > 800 ? "1.6rem" : "auto", position: "absolute", marginTop: (width > 800 || height < 800) ? "20px" : "0", top: (height > 800) ? "calc(51vh + 20px)" : "200px" }}>
            Building Web3 Infrastructure for the Open Internet
  </Text>*/}
        </VStack>
      </Grid>



      <Footer />

    </Box>
  );
}

export default Landing;