import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Image
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import { FaGithub, FaTwitter } from 'react-icons/fa';
import Header from "./Header";
import Footer from "./Footer";
import logoFluid from './logos/fluid.png';
import logoAnzen from './logos/anzen.png';
import logoNeptune from './logos/neptune.png';
import logoFluidTrans from './logos/fluidtrans.png';
import logoAnzenTrans from './logos/anzentrans.png';
import logoMagmaTrans from './logos/magmatrans.png';
import logoNeptuneTrans from './logos/neptunetrans.png';
import logoRoverDark from './logos/roverdark.png';
import logoRoverLight from './logos/roverlight.png';

function Projects() {
  const { colorMode } = useColorMode()
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <Box textAlign="center" bg={useColorModeValue('gray.100', 'gray.900')} fontSize="xl">

      <Header />

      <Grid minH={"calc(100vh - 248px)"} p={0} alignItems="center" justifyContent={"center"}>

        <Link href='https://twitter.com/fluid_protocol'>
          <Image style={{ width: "300px", maxWidth: "100%", padding: "15px" }} src={colorMode === 'light' ? logoFluid : logoFluidTrans} />
        </Link><Link href='https://twitter.com/anzenprotocol'>
          <Image style={{ width: "300px", maxWidth: "100%", padding: "15px" }} src={colorMode === 'light' ? logoAnzen : logoAnzenTrans} />
        </Link><Link href='https://twitter.com/magmastaking'>
          <Image style={{ width: "300px", maxWidth: "100%", padding: "15px" }} src={logoMagmaTrans} />
        </Link><Link href='https://twitter.com/neptune_stable'>
          <Image style={{ width: "300px", maxWidth: "100%", padding: "15px" }} src={colorMode === 'light' ? logoNeptune : logoNeptuneTrans} />
        </Link>
        <Link href='https://x.com/RoverStaking'>
          <Image style={{ width: "300px", maxWidth: "100%", padding: "25px" }} src={colorMode === 'light' ? logoRoverDark : logoRoverLight} />
        </Link>
      </Grid>

      <Footer />

    </Box>
  );
}

export default Projects;