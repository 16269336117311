import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  extendTheme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import Landing from './Landing'
import Projects from "./Projects";
import Team from "./Team";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


// 2. Add your color mode config
const config = {
  initialColorMode: 'dark',
  //  If true, your app will change color mode based on the user's system preferences.
  useSystemColorMode: false,
}

// 3. extend the theme
const mytheme = extendTheme({ config })


const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing/>,
    errorElement: <Landing/>,
  },
  {
    path: "/team",
    element: <Team/>,
    errorElement: <Landing/>,
  },
  {
    path: "/portfolio",
    element: <Projects/>,
    errorElement: <Landing/>,
  },
]);

function App({ cookies }) {
  return (
    <ChakraProvider theme={mytheme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
