import React from 'react';
import { useColorMode, useColorModeValue, IconButton, Box } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

export const ColorModeSwitcher = props => {

  const text = useColorModeValue('dark', 'light');
  const { colorMode, toggleColorMode } = useColorMode()
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <Box
      className="menu-item"
      aria-label={`Switch to ${text} mode`}
      marginLeft="2"
      onClick={toggleColorMode}
    ><SwitchIcon /></Box>
  );
};
